
import { CodigoDescricaoDTO } from "@/dtos/CodigoDescricaoDTO";
import { eParametroPreMatricula } from "@/enums/eParametroPreMatricula";
import { IParametros } from "@/interfaces/IParametros";
import { Deficiencia } from "@/models/Deficiencia";
import { Responsavel } from "@/models/Responsavel";
import { Solicitante } from "@/models/Solicitante";
import { getDeficiencias } from "@/services/deficienciaService";
import { Component, Prop, Vue } from "vue-property-decorator";
import { eParentesco, ParentescoLabel } from "../enums/eParentesco";
import { eSexo, SexoLabel } from "../enums/eSexo";
import { validaCpf, alertError, getDate, validaData, validaCelular, alertWarning } from "../utils/functions";

@Component
export default class ModalResponsavel extends Vue {
  @Prop() ehInfantil!: boolean;
  
  parametros: IParametros = this.$store.state.parametros;
  responsaveis: Responsavel[] = this.$store.state.responsaveis;
  deficiencias: Deficiencia[] = new Array<Deficiencia>();
  deficienciaSelecionada!: Deficiencia;

  valid = false;
  dialog = false;
  itemsParentesco = [
    eParentesco.Mae,
    eParentesco.Pai,
    eParentesco.Tio,
    eParentesco.Primo,
    eParentesco.Avo,
    eParentesco.Cunhado,
    eParentesco.Irmao,
    eParentesco.Madrasta,
    eParentesco.Padrasto,
    eParentesco.Outro,
  ];
  itemsSexo = [eSexo.Masculino, eSexo.Feminino];
  itemsTrabalha = [false, true];
  dataNascimento = "";

  responsavel = new Responsavel();
  validate = {
    nome: [
      (v: string) => !!v || "Nome é obrigatório",
      (v: string) =>
        v.length <= 300 || "Nome deve ter no máximo 300 caracteres",
    ],
    celular: [
      (v: string) => !!v || "Celular é obrigatório",
      (v: string) => validaCelular(v) || "O telefone informado é inválido",
    ],
    parentesco: [
      (v: number) => !!v || v == 0 || "Escolha o nível de parentesco do responsável",
      (v: eParentesco) => v != eParentesco.Pai || !this.$store.state.candidatoNaoPossuiPaiDeclarado || 'Quando o candidato não possui pai declarado, o tipo de parentesco PAI não pode ser informado.'
    ],
    cpf: [
      (v: string) => this.getRulesCpf(v) || "CPF é obrigatório",
      (v: string) => validaCpf(v) || "CPF inválido",
    ],
    renda: [(v: string) => this.getRulesRenda(v) || "Renda é obrigatório"],
    trabalha: [(v: boolean) => v != undefined || "Responsável trabalha?"],
    email: [
      (v: string) =>
        !v || v.length <= 150 || "E-mail não pode ter mais de 150 caracteres",
      (v: string) => !v || /.+@.+/.test(v) || "E-mail inválido",
    ],
    data: [
    (v: string) => this.getRulesDataDeNascimentoResponsavel(v) || "A data de nascimento é obrigatória",
      (v: string) => validaData(v) && !!v || !v || "Data de nascimento inválida",
    ],
    telefoneTrabalho: [
      (v: string) => !!v || "Celular é obrigatório",
      (v: string) => validaCelular(v) || "O telefone informado é inválido",
    ]
  };

  openModal(): void {
    const solicitanteMatricula: Solicitante = this.$store.state.solicitante;
    if(solicitanteMatricula.ehResponsavelDoCandidato && (this.responsaveis.length == 0))
    {
      this.responsavel.nome = solicitanteMatricula.nome;
      this.responsavel.celular = solicitanteMatricula.telefone;
      this.responsavel.celularFixo = solicitanteMatricula.telefoneSecundario || '';
      this.responsavel.email = solicitanteMatricula.email || '';
      this.responsavel.parentesco = solicitanteMatricula.parentesco;
      this.responsavel.cpf = solicitanteMatricula.cpf || '';
      this.responsavel.possuiDeficienciaComLaudo = false;
      this.responsavel.deficienciaId = null;
    }
    this.dialog = true;

    this.responsavel.parentesco = null;

    this.popularDeficiencias();
  }

  salvar(): void {
    let error = false;
    const validacao = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    if (validacao) {
      const responsaveis: Responsavel[] = this.$store.state.responsaveis;

      if (this.responsavel.possuiDeficienciaComLaudo && (this.deficienciaSelecionada == null || this.deficienciaSelecionada.id == 0)) {
        alertWarning('Quando informado que o responsável possui deficiência comprovada, ela deve ser informada.');
        return;
      }

      this.responsavel.deficienciaId = this.deficienciaSelecionada?.id;

      if (responsaveis.length > 0) {
        responsaveis.forEach((respon) => {
          if (this.parametros.cpfResponsaveis && this.responsavel.cpf == respon.cpf) {
            error = true;
          }
        });
      }

      if (error) {
        alertError(
          "Já existe um responsável com esse CPF adicionado."
        );
      } else {
        this.adicionaResponsavel();
      }
    }
  }

  adicionaResponsavel(): void {
    this.$store.commit("addResponsavel", this.responsavel);
    this.responsavel.dataNascimento = getDate(this.dataNascimento);
    this.responsavel = new Responsavel();
    this.dialog = false;
  }

  getParentescoDescription(item: eParentesco) {
    return ParentescoLabel.get(item);
  }

  getSexoDescription(item: eSexo) {
    this.$refs["form"];
    return SexoLabel.get(item);
  }

  getTrabalhaDescription(item: boolean) {
    if (item) {
      return "Sim";
    }
    return "Não";
  }

  getRulesRenda(renda: string): boolean {
    if (this.parametros.informarRenda == eParametroPreMatricula.Sim) return true;
    if (this.parametros.informarRenda == eParametroPreMatricula.SimObrigatorio && (!renda || renda == "R$ 0,00")) return false;
    return true;
  }

  getRulesCpf(cpf: string): boolean {
    if (this.parametros.cpfResponsaveis == eParametroPreMatricula.Sim) return true;
    if (this.parametros.cpfResponsaveis == eParametroPreMatricula.SimObrigatorio && !cpf) return false;
    return true;
  }

  getRulesDataDeNascimentoResponsavel(data: string): boolean {
    if (this.parametros.informarDataDeNascimentoDoResponsavel == eParametroPreMatricula.Sim) return true;
    if (this.parametros.informarDataDeNascimentoDoResponsavel == eParametroPreMatricula.SimObrigatorio && !data) return false;
    return true;
  }

  mostraCampo(campo: string): boolean {
    switch (campo) {
      case 'cpfResponsavel':
        return this.parametros.cpfResponsaveis !== eParametroPreMatricula.Nao;

      case 'renda':
        return this.parametros.informarRenda !== eParametroPreMatricula.Nao;

      case "dataResponsavel":
        return this.parametros.informarDataDeNascimentoDoResponsavel !== eParametroPreMatricula.Nao;

      default:
        return true;
    }
  }

  async popularDeficiencias() {
    const response = await getDeficiencias();
    response.data.forEach((el: CodigoDescricaoDTO) => {
      const deficiencia = new Deficiencia();
      deficiencia.id = el.codigo;
      deficiencia.descricao = el.descricao;
      this.deficiencias.push(deficiencia);
    });
  }
}
