import { IParametros } from '@/interfaces/IParametros';
import { Candidato } from '@/models/Candidato';
import { Responsavel } from '@/models/Responsavel';
import Vuex from 'vuex';
import Vue from 'vue'
import { ConfigMatriculaDTO } from '@/dtos/ConfigMatriculaDTO';
import { Solicitante } from '@/models/Solicitante';
import { Atendimento } from '@/models/Atendimento';
import { Documento } from '@/models/Documento';
import { ParametrosSolicitacaoDeTransferenciaDTO } from '@/dtos/ParametrosSolicitacaoDeTransferenciaDTO';

Vue.use(Vuex); 

const store = new Vuex.Store({
  state: {
    dataHoraToken: new Date(0),
    responsaveis: new Array<Responsavel>(),
    candidato: new Candidato(),
    parametros: {},
    error: '',
    tipos: new Array<ConfigMatriculaDTO>(),
    solicitante: new Solicitante,
    atendimento: new Atendimento,
    ehPeloCallCenter: false,
    documentos: new Array<Documento>(),
    parametrosSolicitacaoDeTransferencia: new ParametrosSolicitacaoDeTransferenciaDTO(),
    candidatoNaoPossuiPaiDeclarado: false
  },
  mutations: {
    addResponsavel(state, responsavel: Responsavel) {
      state.responsaveis.push(responsavel);
    },
    clearState(state) {
      state.responsaveis = new Array<Responsavel>();
      state.documentos = new Array<Documento>();
    },
    setParametros(state, parametros: IParametros){
      state.parametros = parametros;
    },
    removeResponsavel(state, responsavel: Responsavel) {
      const index = state.responsaveis.indexOf(responsavel);
      if (index !== -1) {
        state.responsaveis.splice(index, 1);
      }
    },
    removerResponsaveis(state){
      state.responsaveis = new Array<Responsavel>();
    },
    setCandidato(state, candidato: Candidato) {
      state.candidato = candidato;
    },
    setError(state, error: string) {
      state.error = error;
    },
    setTipos(state, tipos: ConfigMatriculaDTO[]) {
      state.tipos = tipos;
    },
    setSolicitante(state, solicitante: Solicitante) {
      state.solicitante = solicitante;
    },
    setAtendimento(state, atendimento: Atendimento) {
      state.atendimento = atendimento;
    },
    clearAtendimento(state){
      state.solicitante = new Solicitante();
      state.atendimento = new Atendimento();
    },
    setFlagCallCenter(state, ehCallCenter: boolean){    
      state.ehPeloCallCenter = ehCallCenter;
    },
    addDocumento(state, documento: Documento) {
      state.documentos.push(documento);
    },
    removerArquivo(state, documento: Documento) {
      const index = state.documentos.indexOf(documento);
      if (index !== -1) {
        state.documentos.splice(index, 1);
      }
    },
    candidatoNaoPossuiPaiDeclarado(state, naoPossuiPaiDeclarado: boolean) {
      state.candidatoNaoPossuiPaiDeclarado = naoPossuiPaiDeclarado;
    }
  }
});

export default store;