import Swal from 'sweetalert2';
import { IParametros } from '@/interfaces/IParametros';
import app from '@/main';
export function saveByteArray(reportName, byte) {
    const blob = new Blob([byte], {type: "application/pdf"});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName;
    link.download = fileName;
    link.click();
}

export function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
       const ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
}

export function showLoading(title: string) {
    Swal.fire({
        title: title,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
            Swal.showLoading();
        }
    });
}

export function closeLoading() {
    Swal.hideLoading();
    Swal.close();
}

export function confirm(title: string, msg: string) {
    return Swal.fire({
        title: title,
        allowOutsideClick: false,
        html: msg,
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
    });
}

export function confirmSucess(title: string, msg: string) {
    return Swal.fire({
        title: title,
        allowOutsideClick: false,
        html: msg,
        icon: 'success',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
    });
}

export function alertSuccess(msg: string) {
    Swal.fire('Sucesso!', msg, 'success');
}

export function alertError(msg: string) {
    Swal.fire('', msg || 'Falha de comunicação com o servidor.', 'error');
}

export function alertWarning(msg: string) {
    Swal.fire('Atenção!', msg, 'warning');
}

export function alertInfo(msg: string) {
    Swal.fire('Informativo', msg, 'info');
}

export function validaNis(nis: string): boolean {
    if (nis) {
        const multiplicador = [ 3,2,9,8,7,6,5,4,3,2 ];

        let soma;
        let resto;

        if (nis.trim().length != 11) return false;
        nis = nis.trim();
        nis = nis.replace("-", "").replace(".", "").padStart(11, '0');
                
        soma = 0;
        for (let i = 0; i < 10; i++) {
            soma += Number.parseInt(nis[i].toString()) * multiplicador[i];
        }

        resto = soma % 11;
        if ( resto < 2 )
            resto = 0;
        else
            resto = 11 - resto;

        return nis.endsWith(resto.toString());
    }

    return true;
}

export function validaCpf(cpf: string): boolean {
    if (!cpf) return true;
    cpf = cpf.replaceAll(".", "").replaceAll("-", "");

    if (cpf.length != 11) {
        return false;
    }
    if (
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false;
    }
    let numero = 0;
    let caracter = "";
    const numeros = "0123456789";
    let j = 10;
    let somatorio = 0;
    let resto = 0;
    let digito1 = 0;
    let digito2 = 0;
    let cpfAux = "";
    cpfAux = cpf.substring(0, 9);
    for (let i = 0; i < 9; i++) {
        caracter = cpfAux.charAt(i);
        if (numeros.search(caracter) == -1) {
            return false;
        }
        numero = Number(caracter);
        somatorio = somatorio + numero * j;
        j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
        digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i = 0; i < 10; i++) {
        caracter = cpfAux.charAt(i);
        numero = Number(caracter);
        somatorio = somatorio + numero * j;
        j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
        digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf != cpfAux) {
        return false;
    } else {
        return true;
    }
}

export function validaCNS(vlrCNS: string): boolean {
    if (!vlrCNS) return true;
    let soma: number;
    let resto: number;
    let dv: number;
    let resultado: string;
    const tamCNS = vlrCNS.length;
    if ((tamCNS) != 15) {
        return false;
    }
    const pis = vlrCNS.substring(0, 11);
    soma = (((Number(pis.substring(0, 1))) * 15) +
        ((Number(pis.substring(1, 2))) * 14) +
        ((Number(pis.substring(2, 3))) * 13) +
        ((Number(pis.substring(3, 4))) * 12) +
        ((Number(pis.substring(4, 5))) * 11) +
        ((Number(pis.substring(5, 6))) * 10) +
        ((Number(pis.substring(6, 7))) * 9) +
        ((Number(pis.substring(7, 8))) * 8) +
        ((Number(pis.substring(8, 9))) * 7) +
        ((Number(pis.substring(9, 10))) * 6) +
        ((Number(pis.substring(10, 11))) * 5));
    resto = soma % 11;
    dv = 11 - resto;
    if (dv == 11) {
        dv = 0;
    }
    if (dv == 10) {
        soma = (((Number(pis.substring(0, 1))) * 15) +
            ((Number(pis.substring(1, 2))) * 14) +
            ((Number(pis.substring(2, 3))) * 13) +
            ((Number(pis.substring(3, 4))) * 12) +
            ((Number(pis.substring(4, 5))) * 11) +
            ((Number(pis.substring(5, 6))) * 10) +
            ((Number(pis.substring(6, 7))) * 9) +
            ((Number(pis.substring(7, 8))) * 8) +
            ((Number(pis.substring(8, 9))) * 7) +
            ((Number(pis.substring(9, 10))) * 6) +
            ((Number(pis.substring(10, 11))) * 5) + 2);
        resto = soma % 11;
        dv = 11 - resto;
        resultado = pis + "001" + String(dv);
    } else {
        resultado = pis + "000" + String(dv);
    }
    if (vlrCNS != resultado) {
        return false;
    } else {
        return true;
    }
}

export function validaCNSProvisorio(vlrCNS: string): boolean {
    if (!vlrCNS) return true;
    const pis = vlrCNS.substring(0, 15);

    if (pis == "") {
        return false
    }

    if ((vlrCNS.substring(0, 1) != "7") && (vlrCNS.substring(0, 1) != "8") && (vlrCNS.substring(0, 1) != "9")) {
        return false
    }

    const soma = ((parseInt(pis.substring(0, 1), 10)) * 15)
        + ((parseInt(pis.substring(1, 2), 10)) * 14)
        + ((parseInt(pis.substring(2, 3), 10)) * 13)
        + ((parseInt(pis.substring(3, 4), 10)) * 12)
        + ((parseInt(pis.substring(4, 5), 10)) * 11)
        + ((parseInt(pis.substring(5, 6), 10)) * 10)
        + ((parseInt(pis.substring(6, 7), 10)) * 9)
        + ((parseInt(pis.substring(7, 8), 10)) * 8)
        + ((parseInt(pis.substring(8, 9), 10)) * 7)
        + ((parseInt(pis.substring(9, 10), 10)) * 6)
        + ((parseInt(pis.substring(10, 11), 10)) * 5)
        + ((parseInt(pis.substring(11, 12), 10)) * 4)
        + ((parseInt(pis.substring(12, 13), 10)) * 3)
        + ((parseInt(pis.substring(13, 14), 10)) * 2)
        + ((parseInt(pis.substring(14, 15), 10)) * 1);

    const resto = soma % 11;

    if (resto == 0) {
        return true;
    }
    else {
        return false;
    }
}

export function validaData(data: string): boolean {
    const parametros: IParametros = app.$store.state.parametros;
    
    if (parametros.permitirCadastroMaeGestante) return true;
    if (data) {
        if (data.length < 10) return false;

        const dataNascimento = new Date(data.split('/').reverse().join('-'));
        const dataAtual = new Date();

        let anos = dataAtual.getFullYear() -  dataNascimento.getFullYear();

        if (dataAtual.getMonth() <= dataNascimento.getMonth() && dataAtual.getDate() < dataNascimento.getDate()) {
            anos--;
        }

        return anos >= 0;
    }
    return false;
}

export function validaCelular(celular: string): boolean {
    if (celular && celular.length < 13) {
        return false;
    }
    return true;
}

export function validaUnidadeConsumidora(unidadeConsumidora: string): boolean {
    if (unidadeConsumidora && unidadeConsumidora.length > 9) {
        return false;
    }
    return true;
}

export function menorQue5Anos(dataNascimento: string): boolean {
    if (dataNascimento) {
        if (dataNascimento.length < 10) return false;

        const dataAnterior = new Date(dataNascimento.split('/').reverse().join('-'));
        const dataAtual = new Date();

        const diaAtual = dataAtual.getDate();
        const mesAtual = dataAtual.getMonth() + 1;
        const anoAtual = dataAtual.getFullYear();

        let anos = anoAtual - dataAnterior.getFullYear();

        if (mesAtual < dataAnterior.getMonth() || mesAtual == dataAnterior.getMonth() && diaAtual < dataAnterior.getDate()) {
            anos--;
        }

        return anos > 5;        
    }
    return false;
}

export function getDate(dataString: string): Date | null {
    if (dataString) {

        const date = new Date(`${dataString.split('/').reverse().join('-')}T00:00:00`);
        return date;
    }
    return null;
}

export function formatDate(data: string | null) {
    if (data) {
        const dataFormatada = data.substring(0, 10).split('-').reverse().join('/');
        return dataFormatada;
    }

    return null;
}

export function replaceSpecialChars(text): string
{
    if (!text) {
        return '';
    }

    text = text.toString().toLowerCase();                                                         
    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    return text;      
}

export function onFilter(item, queryText): boolean {
    return replaceSpecialChars(item.descricao.toString().toLowerCase()).includes(replaceSpecialChars(queryText))
            ||  replaceSpecialChars(item.descricao).includes(replaceSpecialChars(queryText));
}

export function onFilterString(item, queryText): boolean {
    return replaceSpecialChars(item).includes(replaceSpecialChars(queryText))
            ||  replaceSpecialChars(item).includes(replaceSpecialChars(queryText));
}

export function getEstados(): string[] {
    return [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MS',
        'MT',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO'
    ];
}

export function enumHasValue(enumerador: number | null) {
    return enumerador != null && enumerador != undefined && enumerador >= 0;
}

export function obtenhaCorDeTextoComBaseNaCorDeFundo(c: string): string {
    c = c.substring(1);
    const r = parseInt(c.substring(1,3),16);
    const g = parseInt(c.substring(3,5),16);
    const b = parseInt(c.substring(5,7),16);
    const yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}

export function possuiValor(valor: any) {
    return valor && valor != null && valor != undefined && valor != '';
}

export function idade(nascimento: Date, corte: Date) {
    let diferencaAnos = corte.getFullYear() - nascimento.getFullYear();
    if ((nascimento.getMonth() > corte.getMonth()) || (nascimento.getMonth() == corte.getMonth() && nascimento.getDate() > corte.getDate())) {
        diferencaAnos--;
    }
    return diferencaAnos;
}